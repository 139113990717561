import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import App from "./App";

import mixpanel from "mixpanel-browser";

mixpanel.init("d694cdeec64d10a1fdbf4e277f021c6d");
ReactGA.initialize("G-J00442HMG3");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
